import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ApiService} from '../../core/http/api.service';
import {LocalStorageService} from '../../core/services/local-storage.service';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  loader = false;
  token;
  history = [];
  earnedTotal = 0;

  constructor(private location: Location, private apiServices: ApiService, private auth: LocalStorageService) {
    this.token = this.auth.getValue('token', false);
  }

  ngOnInit(): void {
    this.driverOrderHistory();
  }

  Back() {
    this.location.back();
  }

  driverOrderHistory() {
    this.loader = true;
    const data = {
      token: this.token
    };
    this.apiServices.driverOrderHistory(data).subscribe((res) => {
        this.history = res.response[0];
        this.earnedTotal = 0;
        for (let i = 0; i < this.history.length; i++) {
          this.earnedTotal = parseInt(this.history[i].driver_earning[0].fees_amount, 10) + parseInt(this.history[i].delivery_tip, 10);
        }
        this.loader = false;
      }, (err) => {
        console.log(err);
      }
    );
  }
}
