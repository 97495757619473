import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../core/http/api.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {SnotifyService} from 'ng-snotify';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  signInObj = {
    email: '',
    password: '',
    disabled: false
  };
  otp;

  constructor(private apiServices: ApiService,
              private router: Router,
              private auth: LocalStorageService,
              private snotifyService: SnotifyService) {
  }
  ngOnInit(): void {
  }

  signIn() {
    this.signInObj.disabled = true;
    this.apiServices.driverLogin(this.signInObj).subscribe(res => {
      this.signInObj.disabled = false;
      this.auth.setValue(environment.authKey, res.response[0].token);
      this.auth.setValue('token', res.response[0].token);
      console.log(res.response[0].token);
      this.router.navigate(['dashboard']);
      // this.auth.setValue('number', this.signInObj.phone_number);
      // this.auth.setValue('otp', res.response.otp );
      // this.router.navigate(['/otp']);
    }, (err) => {
      this.snotifyService.error(err.error.message, err.error.response);
      this.signInObj.disabled = false;
    });
  }

}
