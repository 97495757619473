import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {ApiService} from '../../core/http/api.service';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.css']
})
export class OrderCompleteComponent implements OnInit {
  token;
  loader = false;
  tracking;
  /*orders: any = [{
    id: '',
    order_id: '',
    billing_name: '',
    total_amount: '',
    orders: [],
    delivery_feestype: '',
    restaurant: {
      name: '',
      address: ''
    },
    driver_to_restaurant: {
      time: ''
    },
    restaurant_to_customer: {
      time: ''
    },
    track_order: ''
  }];*/
  delivered;
  feedback = {
    smile: '',
    sad: ''
  };

  acceptedAt;
  restaurantArrivedTiming;
  pickedAt;
  atCustomerLocation;
  id;
  singleOrder;
  public lat = 45.2972066;
  public lng = -75.8890506;
  markerlati;
  markerlongi;
  public origin: any;
  public destination: any;
  userLocation: any = {
    long: '',
    lat: '',
    address: '1600 Merivale Rd Ottawa, Ontario, ON K2G 5J8'
  };
  geoWatch;

  constructor(private auth: LocalStorageService, private apiServices: ApiService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.token = this.auth.getValue('token', false);
    this.acceptedAt = this.auth.getValue('acceptedAt', false);
    this.restaurantArrivedTiming = this.auth.getValue('restaurantArrived', false);
    this.pickedAt = this.auth.getValue('pickedAt', false);
    this.atCustomerLocation = this.auth.getValue('atCustomerLocation', false);
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit(): void {
    // this.getSingleOrder();
    this.getOrderDetails();
    if (window.navigator.geolocation) {
      window.navigator.geolocation.watchPosition((position) => {
        this.userLocation.long = position.coords.longitude;
        this.userLocation.lat = position.coords.latitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    if (!this.geoWatch) {
      if ('geolocation' in navigator && 'watchPosition' in navigator.geolocation) {
        this.geoWatch = window.navigator.geolocation.watchPosition(
          position => {
            this.userLocation.long = position.coords.longitude;
            this.userLocation.lat = position.coords.latitude;
          });
      }
    }
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.userLocation.long = position.coords.longitude;
          this.userLocation.lat = position.coords.latitude;
          this.origin = {
            lat: 45.2972066,
            lng: -75.8890506,
          };
          this.destination = {
            lat: 45.2956566,
            lng: -75.8845406
          };
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    }
  }
  /*getSingleOrder() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      order_id: this.id,
      token: this.token
    };
    this.apiServices.viewSingleOrder(data).subscribe((res) => {
        if (res.response) {
          this.singleOrder = res.response[0];
          this.markerlati = this.userLocation.lat;
          this.markerlongi = this.userLocation.long;
          if (this.singleOrder.track_order !== 5) {
            this.router.navigate(['/dashboard']);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }*/
  getOrderDetails() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      order_id: this.id,
      token: this.token
    };
    this.apiServices.viewOrderDetails(data).subscribe((res) => {
        if (res.response) {
          this.singleOrder = res.response[0];
          this.markerlati = this.userLocation.lat;
          this.markerlongi = this.userLocation.long;
          if (this.singleOrder.track_order !== 5) {
            this.router.navigate(['/dashboard']);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }
  /*getPendingOrders() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      token: this.token
    };
    this.apiServices.getPendingOrders(data).subscribe((res) => {
        this.orders = res.response;
        if (this.orders[0].track_order !== 5) {
          this.router.navigate(['/delivery']);
        }
        this.loader = false;
      }, (err) => {
        console.log(err);
      }
    );
  }*/

  deliveredToCustomer() {
    const data = {
      order_id: this.id,
      token: this.token,
      latitude: 45.2972066,
      longitude: -75.8890506,
      status: 2,
    };
    this.apiServices.orderComplete(data).subscribe((res) => {
        $('#feedbackModal').modal('show');
        this.auth.remove('acceptedAt');
        this.auth.remove('restaurantArrived');
        this.auth.remove('pickedAt');
        this.auth.remove('atCustomerLocation');
        this.delivered = res.response;
        /*this.router.navigate(['/restaurant']);*/
      }, (err) => {
        console.log(err);
      }
    );
  }

  submitFeedback() {
    /*  console.log(this.feedback);
      const data = {
        order_id: this.orders[0].id,
        token: this.token,
        latitude: 45.2972066,
        longitude: -75.8890506,
        status: 2,
      };*/
    // this.apiServices.orderComplete(data).subscribe((res) => {
    this.auth.remove('acceptedAt');
    this.auth.remove('restaurantArrived');
    this.auth.remove('pickedAt');
    this.auth.remove('atCustomerLocation');
    $('#feedbackModal').modal('hide');
    this.router.navigate(['/dashboard']);
  }

  close() {
    this.auth.remove('acceptedAt');
    this.auth.remove('restaurantArrived');
    this.auth.remove('pickedAt');
    this.auth.remove('atCustomerLocation');
    $('#feedbackModal').modal('hide');
    this.router.navigate(['/dashboard']);
  }

  /*orderTracking() {
    const data = {
      order_id: this.orders[0].id,
      status: 3, // 3 for arrived at restaurant , 4 for food picked up, 5 for arrived at customer
      token: this.token
    };
    this.apiServices.orderTracking(data).subscribe((res) => {
        this.tracking = res.response;
        this.router.navigate(['/pick-food']);
        console.log(res);
      }, (err) => {
        console.log(err);
      }
    );
  }*/
}
