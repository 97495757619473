import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {ApiService} from '../../core/http/api.service';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  token;
  loader = false;
  allOrders: any = [{
    id: '',
    declined_reasons: [],
    restaurant: {
      name: '',
      address: ''
    },
    driver_to_restaurant: {
      time: ''
    },
    restaurant_to_customer: {
      time: ''
    },
    delivery_feestype: '',
    delivery_amount: '',
    total_amount: '',
    total_time: '',
    track_order: '',
  }];
  userLocation = {
    long: 77.55,
    lat: 55.55,
    address: '1600 Merivale Rd Ottawa, Ontario, ON K2G 5J8'
  };
  dutyCheck;
  driverStatus = 1;
  constructor(private location: Location, private auth: LocalStorageService,
              private apiServices: ApiService, private router: Router, private snotifyService: SnotifyService) {
    this.token = this.auth.getValue('token', false);
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.userLocation.long = position.coords.longitude;
          this.userLocation.lat = position.coords.latitude;
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    }
  }

  ngOnInit(): void {
    $(document).ready(function() {
      $('#color_mode').on('change', function() {
        colorModePreview(this);
      });
    });

    function colorModePreview(ele) {
      if ($(ele).prop('checked') === true) {
        $('#duty').addClass('dark-preview');
        $('#duty').removeClass('white-preview');
      } else if ( $(ele).prop('checked') === false) {
        $('#duty').addClass('white-preview');
        $('#duty').removeClass('dark-preview');
      }
    }
    this.getAllOrders();
    this.getDriverDuty();
  }

  getAllOrders() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      token: this.token
    };
    this.apiServices.getAllOrders(data).subscribe((res) => {
      this.loader = false;
      if (res.response) {
          this.allOrders = res.response[0];
          for (let i = 0; i < this.allOrders.length; i++) {
            if (this.allOrders[i].track_order !== 0) {
              console.log(this.allOrders[i].id);
              this.router.navigate(['/restaurant/' + this.allOrders[i].id]);
            }
          }
          // if (this.orders[0].track_order !== 0) {
          //   this.router.navigate(['/restaurant']);
          // }
        }
      }, (err) => {
        this.loader = false;
        console.log(err);
      }
    );
  }

  refreshOrders() {
    this.getAllOrders();
  }

  ChangeDuty() {
    console.log(this.dutyCheck);
    if (this.dutyCheck === true) {
      this.driverStatus = 1;
    } else if (this.dutyCheck === false) {
      this.driverStatus = 0;
    }
    console.log(this.driverStatus);
    const data = {
      token: this.token,
      status: this.driverStatus
    };
    this.apiServices.driverStatus(data).subscribe( res => {
      this.snotifyService.success('Successfully Changed Status', 'Success');
    });
  }
  getDriverDuty() {
    this.apiServices.getDriverStatus({token: this.token}).subscribe( res => {
      console.log(res.response.data[0]);
      console.log(res.response.data[0].driver_is_on_duty);
      this.dutyCheck = res.response.data[0].driver_is_on_duty;
    });
  }
  /*getAssignedOrders() {
    this.loader = true;
    const data = {
      /!* latitude: this.userLocation.lat,
       longitude: this.userLocation.long,*!/
      latitude: 45.2972066,
      longitude: -75.8890506,
      address: this.userLocation.address,
      token: this.token
    };
    this.apiServices.getPendingOrders(data).subscribe((res) => {
        if (res.response) {
          this.orders = res.response;
          if (this.orders[0].track_order !== 0) {
            this.router.navigate(['/restaurant']);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }*/

  Back() {
    this.location.back();
  }

  selectSingleOrder() {
    this.router.navigate(['/delivery']);
  }

  HistoryView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/history']);
  }

  ProfileView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/profile']);
  }

  HelpView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/help']);
  }

  SettingsView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/settings']);
  }

  AssignOrder() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/dashboard']);
  }
}
