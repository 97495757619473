import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {ApiService} from '../../core/http/api.service';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-at-restaurant',
  templateUrl: './at-restaurant.component.html',
  styleUrls: ['./at-restaurant.component.css']
})
export class AtRestaurantComponent implements OnInit {
  token;
  loader = false;
  tracking = [{
    delivery_timeline: []
  }];
  /*orders: any = [{
    id: '',
    order_id: '',
    billing_name: '',
    total_amount: '',
    orders: [],
    restaurant: {
      name: '',
      address: ''
    },
    driver_to_restaurant: {
      time: ''
    },
    restaurant_to_customer: {
      time: ''
    },
    track_order: ''
  }];*/
  acceptedAt;
  id;
  singleOrder;
  public lat = 45.2972066;
  public lng = -75.8890506;
  markerlati;
  markerlongi;
  public origin: any;
  public destination: any;
  userLocation: any = {
    long: '',
    lat: '',
    address: '1600 Merivale Rd Ottawa, Ontario, ON K2G 5J8'
  };
  geoWatch;

  constructor(private auth: LocalStorageService, private apiServices: ApiService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.token = this.auth.getValue('token', false);
    this.acceptedAt = this.auth.getValue('acceptedAt', false);
  }

  ngOnInit(): void {
    // this.getSingleOrder();
    this.getOrderDetails();
    // this.restaurantArrivedTiming = this.auth.getValue('restaurantArrived', false);
    // this.getPendingOrders();
    if (window.navigator.geolocation) {
      window.navigator.geolocation.watchPosition((position) => {
        console.log(position);
        this.userLocation.long = position.coords.longitude;
        this.userLocation.lat = position.coords.latitude;
        // this.showTrackingPosition(position);
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    if (!this.geoWatch) {
      if ('geolocation' in navigator && 'watchPosition' in navigator.geolocation) {
        this.geoWatch = window.navigator.geolocation.watchPosition(
          position => {
            this.userLocation.long = position.coords.longitude;
            this.userLocation.lat = position.coords.latitude;
          });
      }
    }
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          // this.positions = position;
          this.userLocation.long = position.coords.longitude;
          this.userLocation.lat = position.coords.latitude;

          this.origin = {
            // lat: parseFloat(this.singlerideDetails.source_latitude),
            lat: 45.2972066,
            // lng: parseFloat(this.singlerideDetails.source_longitude)
            lng: -75.8890506,
          };
          this.destination = {
            lat: 45.2956566,
            lng: -75.8845406
          };
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    }
    this.origin = {
      // lat: parseFloat(this.singlerideDetails.source_latitude),
      lat: 45.2972066,
      // lng: parseFloat(this.singlerideDetails.source_longitude)
      lng: -75.8890506,
    };
    this.destination = {
      lat: 45.2956566,
      lng: -75.8845406
    };

  }

  /*
    getPendingOrders() {
      this.loader = true;
      const data = {
        latitude: 45.2972066,
        longitude: -75.8890506,
        token: this.token
      };
      this.apiServices.getPendingOrders(data).subscribe((res) => {
          this.orders = res.response;
          if (this.orders[0].track_order !== 2) {
            this.router.navigate(['/pick-food']);
          }
          this.loader = false;
        }, (err) => {
          console.log(err);
        }
      );
    }*/
  // getSingleOrder() {
  //   this.loader = true;
  //   const data = {
  //     latitude: 45.2972066,
  //     longitude: -75.8890506,
  //     order_id: this.id,
  //     token: this.token
  //   };
  //   this.apiServices.viewSingleOrder(data).subscribe((res) => {
  //       if (res.response) {
  //         this.singleOrder = res.response[0];
  //         this.markerlati = this.userLocation.lat;
  //         this.markerlongi = this.userLocation.long;
  //         if (this.singleOrder.track_order !== 2) {
  //           this.router.navigate(['/pick-food/' + this.id]);
  //         }
  //         this.loader = false;
  //       }
  //     }, (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  getOrderDetails() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      order_id: this.id,
      token: this.token
    };
    this.apiServices.viewOrderDetails(data).subscribe((res) => {
        if (res.response) {
          this.singleOrder = res.response[0];
          this.markerlati = this.userLocation.lat;
          this.markerlongi = this.userLocation.long;
          if (this.singleOrder.track_order !== 2) {
            this.router.navigate(['/pick-food/' + this.id]);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }


  orderTracking() {
    const data = {
      order_id: this.id,
      status: 3, // 3 for arrived at restaurant , 4 for food picked up, 5 for arrived at customer
      token: this.token
    };
    this.apiServices.orderTracking(data).subscribe((res) => {
        this.tracking = res.response;
        this.auth.setValue('restaurantArrived', this.tracking[0].delivery_timeline[1].created_at);
        this.router.navigate(['/pick-food/' + this.id]);
        console.log(res);
      }, (err) => {
        console.log(err);
      }
    );
  }

  HistoryView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/history']);
  }

  ProfileView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/profile']);
  }

  HelpView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/help']);
  }

  SettingsView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/settings']);
  }
}
