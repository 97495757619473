<div>
  <div class="d-block d-lg-none py-1 response-nav">
    <div class="row m-0">
      <div class="col-3 text-left">
        <a class="d-flex justify-content-start burgerMenu pt-2" (click)="Back()"><i class="fa fa-arrow-left"></i></a>
      </div>
      <div class="col-6 text-white pt-2">
        <h6><b>Welcome To Karara</b></h6>
      </div>
      <div class="col-3">
        <a class="navbar-brand" href="javascript:void(0)">
          <img alt="" src="./assets/images/karara-logo.png" width="60px" class="logoAlign">
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-2 text-left">
    <h4>History</h4>
    <hr>
  </div>
  <div style="overflow-y: auto; height: 80vh" *ngIf="loader == false">
    <div class="card p-2 m-2 backStyle" *ngFor="let hist of history;">
        <div class="col-12 orderHeading">
          <a style="text-decoration: underline">
            <h6 class="styleContent">OrderID: {{hist.order_id}}</h6>
          </a>
        </div>
      <div class="row mt-2 m-0">
        <div class="col-4 text-center">
          <p class="styleHeading">Delivered</p>
          <h6 class="styleContent">{{hist.updated_at | date: "d MMM, yyyy hh:mm"}}</h6>
        </div>
        <div class="col-5 p-0 text-center">
          <p class="styleHeading">Address</p>
          <h6 class="styleContent">{{hist.flat_no}}, {{hist.address}}, {{hist.district}}</h6>
        </div>
        <div class="col-3 text-center">
          <p class="styleHeading">Earned</p>
          <h6 class="styleContent" *ngIf="hist.driver_earning[0]">${{earnedTotal}}</h6>
          <h6 class="styleContent" *ngIf="!hist.driver_earning[0]">NA</h6>
        </div>
      </div>
      <hr style="background: white">
      <div class="row m-0">
        <div class="col-8">
          <p class="m-0">Delivered To <b>{{hist.billing_name}}</b></p>
        </div>
        <div class="col-4 text-right">
          <p class="m-0">Total <b>${{hist.total_amount}}</b></p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loader == true">
    <div class="col-12 text-center mt-5">
      <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
    </div>
  </div>
</div>
