import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OtpComponent } from './components/otp/otp.component';
import { AllDeliveriesComponent } from './components/all-deliveries/all-deliveries.component';
import { AtRestaurantComponent } from './components/at-restaurant/at-restaurant.component';
import { PickFoodComponent } from './components/pick-food/pick-food.component';
import { ArrivedComponent } from './components/arrived/arrived.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component';
import { HistoryComponent } from './components/history/history.component';
import { HelpComponent } from './components/help/help.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfileComponent } from './components/profile/profile.component';
import {AgmCoreModule} from '@agm/core';
import {SnotifyModule, SnotifyPosition, SnotifyService} from 'ng-snotify';
import {AgmDirectionModule} from 'agm-direction';
export const ToastConfig = {
  global: {
    newOnTop: true,
    maxOnScreen: 8,
    maxAtPosition: 8,
    filterDuplicates: false
  },
  toast: {
    type: 'error',
    showProgressBar: false,
    timeout: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    bodyMaxLength: 150,
    titleMaxLength: 16,
    backdrop: -1,
    icon: null,
    iconClass: null,
    html: null,
    position: SnotifyPosition.rightBottom,
    animation: {enter: 'fadeIn', exit: 'fadeOut', time: 400}
  },
  type: {
    prompt: {
      timeout: 0,
      closeOnClick: false,
      buttons: [
        {text: 'Ok', action: null, bold: true},
        {text: 'Cancel', action: null, bold: false},
      ],
      placeholder: 'Enter answer here...',
      type: 'prompt',
    },
    confirm: {
      timeout: 0,
      closeOnClick: false,
      buttons: [
        {text: 'Ok', action: null, bold: true},
        {text: 'Cancel', action: null, bold: false},
      ],
      type: 'confirm',
    },
    simple: {
      type: 'simple'
    },
    success: {
      type: 'success'
    },
    error: {
      showProgressBar: false,
      timeout: 5000,
      type: 'error'
    },
    warning: {
      type: 'warning'
    },
    info: {
      type: 'info'
    },
    async: {
      pauseOnHover: false,
      closeOnClick: false,
      timeout: 0,
      showProgressBar: false,
      type: 'async'
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    OtpComponent,
    AllDeliveriesComponent,
    AtRestaurantComponent,
    PickFoodComponent,
    ArrivedComponent,
    FeedbackComponent,
    OrderCompleteComponent,
    HistoryComponent,
    HelpComponent,
    SettingsComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    SnotifyModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AgmCoreModule.forRoot(),
    AgmDirectionModule,     // agm-direction
  ],
  providers: [
    {provide: 'SnotifyToastConfig', useValue: ToastConfig},
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
