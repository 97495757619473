<div>
  <div class="d-block d-lg-none py-1 response-nav">
    <div class="row m-0">
      <div class="col-3 text-left">
               <!--<span class="d-flex justify-content-start burgerMenu" data-toggle="modal" data-target="#modal-menu"
                     onclick="openNav()">&#9776;</span>-->
      </div>

      <div class="col-6 text-white pt-2">
        <h6><b>Welcome To Karara</b></h6>
      </div>
      <div class="col-3">
        <a class="navbar-brand" href="javascript:void(0)">
          <img alt="" src="./assets/images/karara-logo.png" width="60px" class="logoAlign">
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="singleOrder && loader == false">
  <div class="card shadow p-1">
    <div class="row m-0">
      <div class="col-12">
        <p class="lightSmallFont">PICK UP FROM</p>
        <h5 class="red text-center "><b>{{singleOrder.restaurant.name}}, {{singleOrder.restaurant.address}}</b></h5>
      </div>
      <div class="col-1"><input type="checkbox" checked disabled name="pointer"></div>
      <div class="col-4 text-center"><p style="border-bottom: 1px solid lavender; padding-top: 0.5rem; margin: 0"></p></div>
      <div class="col-1"><input type="checkbox" checked disabled name="pointer"></div>
      <div class="col-4 text-center"><p style="border-bottom: 1px solid lavender; padding-top: 0.5rem; margin: 0"></p></div>
      <div class="col-1"><input type="checkbox" checked disabled name="pointer"></div>
      <div class="col-6 text-center"><p class="redSmallFont">{{singleOrder.driver_to_restaurant.time}}</p></div>
      <div class="col-6 text-center"><p class="redSmallFont pr-5">{{singleOrder.restaurant_to_customer.time}}</p></div>
      <div class="col-4 text-left"><p class="lightSmallFont" style="color: darkgreen">ACCEPTED<br>{{acceptedAt | date: "hh:mm a"}}</p></div>
      <div class="col-4 text-center"><p class="lightSmallFont" style="color: darkgreen">RESTAURANT<br>{{restaurantArrivedTiming | date: "hh:mm a"}}</p></div>
      <div class="col-4 text-right"><p class="lightSmallFont" style="color: darkgreen">CUSTOMER<br>{{atCustomerLocation  | date: "hh:mm a"}}</p></div>
    </div>
  </div>
  <div class="text-center">
    <agm-map [latitude]="lat" [longitude]="lng">
      <agm-direction
        [origin]="origin"
        [destination]="destination">
      </agm-direction>
      <agm-marker [iconUrl]="
  {
    url: './assets/images/pizza.png',
    scaledSize: {
        width: 40,
        height: 30
    }
}" [latitude]='markerlati' [longitude]='markerlongi' [agmFitBounds]="true">
      </agm-marker>
    </agm-map>
  </div>

  <input type="checkbox" name="toggle" id="toggle" />
  <label for="toggle"></label>
  <div class="container">
  </div>

  <div class="card orderShadow message">
    <div class="row m-0 p-1">
      <div class="col-12 red">
        <h5>ORDER ID: {{singleOrder.order_id}}</h5>
      </div>
      <div class="col-md-12">
        <p class=" mb-0">Deliver To: <b> {{singleOrder.flat_no}} {{singleOrder.address}} {{singleOrder.landmark}} {{singleOrder.district}}</b></p>
        <p class=" pt-0"> Contact no: <b>{{singleOrder.billing_number}}</b></p>
      </div>
      <div class="col-4 text-center">
        <p class="lightSmallFont">CUSTOMER</p>
        <h5><b>{{singleOrder.billing_name}}</b></h5>
      </div>
      <div class="col-4 text-center">
        <p class="lightSmallFont">ORDER TOTAL</p>
        <h5><b>${{singleOrder.total_amount}}</b></h5>
      </div>
      <div class="col-4 text-center">
        <p class="lightSmallFont">PAYMENT</p>
        <h5 class="mainColor"><b>PAID</b></h5>
      </div>
      <div class="col-1"></div>
      <div class="col-10 text-center py-1"><p style="border-bottom: 1px solid lavender"></p></div>
      <div class="col-1"></div>
      <div class="col-12">
        <p class="lightSmallFont text-left"> ORDER CONTAINS </p>
      </div>
      <div class="col-12" *ngFor="let allOrders of singleOrder.orders; let i = index">
        <h5 class="lightFont">{{allOrders.menus.index}}{{allOrders.menus[0].name}}.  x {{allOrders.count}}</h5>
      </div>
    </div>
  </div>
    <!--<div class="row m-0">
      <div class="col-12 bordered text-center">
        <h6 class="mb-2">Deliver To:<b>{{singleOrder.address}} </b></h6>
        <p class="mb-1">Contact no: <b>{{singleOrder.billing_number}}</b> </p>
      </div>
    </div>-->
  <div class="col-12 py-2" style="position: absolute; bottom: 1em">
    <button class="btn btnColorMain text-center" style="border-radius: 50px"><a (click)="deliveredToCustomer()"><h5 class="text-white buttonText">DELIVERED</h5></a></button>
  </div>
  </div>
  <div *ngIf="!singleOrder && loader == false">
    <div class="col-12 text-center mt-5">
      <i style="font-size: 50px" class="fa fa-frown-o"> </i>
      <p>OOPS! No Order Assigned Yet</p>
    </div>
  </div>
  <div *ngIf="loader == true">
    <div class="col-12 text-center mt-5">
      <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
    </div>
  </div>
</div>


<div class="modal fade" id="feedbackModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background: #37a783;
    color: white;">
        <h5 class="modal-title" id="exampleModalLongTitle">Your Feedback Is Valuable!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: #37a783;">
        <div class="col-12 text-center py-1 pt-2">
          <h4 style="color: yellow"><b>DELIVERY COMPLETED !</b></h4>
        </div>
        <div class="col-12 text-center py-1">
          <i style="font-size: 3rem; color: yellow" class="fa fa-check-circle"></i>
        </div>
        <div class="col-12 text-center py-1">
          <p style="color: yellow; font-size: 1rem">YOU EARNED</p>
        </div>
        <div class="col-12 text-center m-0">
          <h1 style="color: yellow; font-size: 4rem" *ngIf="singleOrder.delivery_feestype == 0 || singleOrder.delivery_feestype == 1"><b>${{singleOrder.delivery_amount}}</b></h1>
          <h1 style="color: yellow; font-size: 4rem" *ngIf="singleOrder.delivery_feestype == 2"><b>Hourly Based</b></h1>
        </div>
        <div class="col-12 text-center"><p style="border-bottom: 1px solid lavender;"></p></div>
        <div class="row m-0">
          <div class="col-12 text-center">
            <h6 style="color: #aacbc0"><b>Rate the customer?</b></h6>
          </div>
          <div class="col-6 text-center py-1">
            <input type="radio" name="feeling" id="feelingSad" style="opacity: 0" [(ngModel)]="feedback.sad" value="sad" />
           <label for="feelingSad" class="colorFit"><i style="font-size: 4rem;" class="fa fa-frown-o ">
           </i></label>
          </div>
          <div class="col-6 text-center py-1">
            <label for="feelingHappy" class="colorFit"><i style="font-size: 4rem;" class="fa fa-smile-o"></i></label>
            <input type="radio" name="feeling" id="feelingHappy" style="opacity: 0" [(ngModel)]="feedback.smile" value="happy" />
          </div>
        </div>

        <div class="col-12 py-1">
          <button class="btn" style="border-radius: 50px; background-color: white; width: 100%"><a (click)="submitFeedback()"><h3 class=" text-center mainColor" style="font-size: 1rem"><b>SUBMIT</b></h3></a></button>
        </div>
        <div class="col-12 py-1 text-center">
          <a href="#"><h6 class="lightFont"><a (click)="close()">SKIP RATING</a></h6></a>
        </div>
      </div>
    </div>
  </div>
</div>
