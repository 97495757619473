<div>
  <div class="d-block d-lg-none py-1 response-nav">
    <div class="row m-0">
      <div class="col-3 text-left">
          <span class="d-flex justify-content-start burgerMenu" data-toggle="modal" data-target="#modal-menu">&#9776;</span>
      </div>
      <div class="modal left fade" id="modal-menu" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="cross-icon-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span class="text-white"
                aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-12 text-center">
                <a class="navbar-brand" href="javascript:void(0)">
                  <img alt="" src="./assets/images/karara-logo.png" width="220" class="logoAlign pl-3 pb-3 pt-0">
                </a>
              </div>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="ProfileView()">
                    <p> <i class="fa fa-user"></i> Profile</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="AssignOrder()">
                    <p> <i class="fa fa-shopping-cart"></i> Order</p>
                  </a>
                </li>
                <li class=" nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="HistoryView()">
                    <p> <i class="fa fa-history"></i> Deliveries</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="HelpView()">
                    <p> <i class="fa fa-hand-o-left"></i> Help</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="SettingsView()">
                    <p> <i class="fa fa-steam"></i> Settings</p>
                  </a>
                </li>
              </ul>
            </div>
          </div><!-- modal-content -->
        </div><!-- modal-dialog -->
      </div>
      <div class="col-6 text-white pt-2">
        <h6><b>Welcome To Karara</b></h6>
      </div>
      <div class="col-3">
        <a class="navbar-brand" href="javascript:void(0)">
          <img alt="" src="./assets/images/karara-logo.png" width="60px" class="logoAlign">
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="singleOrder && loader == false">
  <div class="card shadow p-1">
    <div class="row m-0">
      <div class="col-12">
        <p class="lightSmallFont">PICK UP FROM</p>
        <h5 class="red text-center "><b>{{singleOrder.restaurant.name}}, {{singleOrder.restaurant.address}}</b></h5>
      </div>
      <div class="col-1"><input type="checkbox" disabled checked name="pointer"></div>
      <div class="col-4 text-center"><p style="border-bottom: 1px solid lavender; padding-top: 0.5rem; margin: 0"></p></div>
      <div class="col-1"><input type="checkbox"  disabled name="pointer"></div>
      <div class="col-4 text-center"><p style="border-bottom: 1px solid lavender; padding-top: 0.5rem; margin: 0"></p></div>
      <div class="col-1"><input type="checkbox" disabled name="pointer"></div>
      <div class="col-6 text-center"><p class="redSmallFont">{{singleOrder.driver_to_restaurant.time}}</p></div>
      <div class="col-6 text-center"><p class="redSmallFont pr-5">{{singleOrder.restaurant_to_customer.time}}</p></div>
      <div class="col-4 text-left"><p class="lightSmallFont">YOU</p></div>
      <div class="col-4 text-center"><p class="lightSmallFont">RESTAURANT</p></div>
      <div class="col-4 text-right"><p class="lightSmallFont">CUSTOMER</p></div>
    </div>
  </div>
  <div class="text-center">
    <agm-map [latitude]="lat" [longitude]="lng">
      <agm-direction
        [origin]="origin"
        [destination]="destination">
      </agm-direction>
      <agm-marker [iconUrl]="
  {
    url: './assets/images/pizza.png',
    scaledSize: {
        width: 40,
        height: 30
    }
}" [latitude]='markerlati' [longitude]='markerlongi' [agmFitBounds]="true">
      </agm-marker>
    </agm-map>
    <!--<agm-map [latitude]="lat" [longitude]="lng">
      <agm-direction
        [origin]="origin"
        [destination]="destination">
      </agm-direction>
      <agm-marker [iconUrl]="
  {
    url: '../../../assets/images/driver.png',
    scaledSize: {
        width: 40,
        height: 30
    }
}" [latitude]='markerlati' [longitude]='markerlongi' [agmFitBounds]="true">
      </agm-marker>
    </agm-map>-->
<!--    <img src="./assets/images/map.png" width="370px" height="320px"/>-->
<!--    <ngx-google-map [mapType]="'ROADMAP'" id="keywords-input" [multiplePlaces]="false"></ngx-google-map>-->
  </div>
  <div class="row m-0 text-center py-3">
    <div class="col-6">
      <h5 class="red" *ngIf="singleOrder.delivery_feestype == 0 || singleOrder.delivery_feestype == 1"><b>$ {{singleOrder.delivery_amount}}</b></h5>
      <h5 class="red" *ngIf="singleOrder.delivery_feestype == 2"><b>Hourly Based</b></h5>
      <p class="lightSmallFont">YOUR PAYMENT</p>
    </div>
    <div class="col-6">
      <h5 class="red"><b>{{singleOrder.driver_to_restaurant.time}}</b></h5>
      <p class="lightSmallFont">TOTAL DURATION</p>
    </div>
  </div>
    <div class="col-12 text-center">
      <a class="lightFont" style="text-decoration: underline" routerLink="/dashboard">BACK TO ORDERS</a>
    </div>
  <div class="row m-0 text-center py-1">
    <div class="col-6 floatDecline">
      <button class="btn btnColorSecondary" type="button" data-toggle="modal" data-target="#exampleModalCenter">DECLINE</button>
    </div>
    <div class="col-6 float">
      <button class="btn btnColorMain" *ngIf="loaderAccept == false"><a (click)="driverAcceptOrder()" class="text-white">ACCEPT</a></button>
      <button class="btn btnColorMain" disabled *ngIf="loaderAccept == true"><a class="text-white">ACCEPT</a></button>
    </div>
  </div>
  </div>
  <div *ngIf="!singleOrder && loader == false">
    <div class="col-12 text-center mt-5">
      <i style="font-size: 50px" class="fa fa-frown-o"> </i>
      <p>Not Found!</p>
    </div>
    <div class="col-12 text-center mt-2">
     <button class="btn btn-primary" (click)="getAllOrders()">All Orders</button>
    </div>
  </div>
  <div *ngIf="loader == true">
    <div class="col-12 text-center mt-5">
      <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content declineModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Decline Order</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form ngNativeValidate (submit)="driverDeclineOrder()">
      <div class="modal-body">
        <div class="col-md-12 form-group" *ngIf="singleOrder">
          <label for="cancel_reason">Reason For Cancelling</label>
          <select class="form-control" name="reasons" id="cancel_reason" [(ngModel)]="reason" required>
            <option value="" selected>Select your reason</option>
            <option [value]="list.reason" *ngFor="let list of singleOrder.declined_reasons">{{list.reason}}</option>
          </select>
        </div>
        <div class="col-md-12 form-group text-left" *ngIf="reason == 'Other'">
          <input type="text" class="form-control text-left" placeholder="Please enter your reason">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-outline-danger">Decline Order</button>
      </div>
      </form>
    </div>
  </div>
</div>
