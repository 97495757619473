import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../services/local-storage.service';
import {Observable} from 'rxjs';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient,
              private storageService: LocalStorageService) {
  }

  driverLogin(inputData): Observable<any> { // login
    return this.httpClient.post('driver-login', inputData);
  }

  verifyOtp(otp): Observable<any> { // verify-otp-for-driver
    return this.httpClient.post('verify-driver-otp', otp);
  }
  getPendingOrders(data): Observable<any> { // ordersOverview
    return this.httpClient.post('get-pending-orders', data);
  }
 /* getAssignedOrders(token): Observable<any> { // get-order
    return this.httpClient.post('get-assigned-order', token);
  }*/
  driverAcceptOrder(data): Observable<any> { // accept-order
    return this.httpClient.post('driver-accept-order', data);
  }
  driverDeclineOrder(data): Observable<any> { // decline-order
    return this.httpClient.post('driver-decline-order', data);
  }
  orderTracking(data): Observable<any> { // changing-order-status-arrived-at restaurant-and-customer
    return this.httpClient.post('order-tracking', data);
  }
  driverFeedback(data): Observable<any> { // driver-feedback-where-driver-can-share-his-feedback-to-deliver
    return this.httpClient.post('feedback', data);
  }
  orderComplete(data): Observable<any> { // order-delivered-after-delivering-the-package
    return this.httpClient.post('order-complete', data);
  }
  driverOrderHistory(data): Observable<any> { // History-for-delivered-items
    return this.httpClient.post('driver-order-history', data);
  }
  getAllOrders(data): Observable<any> { // all-orders-assigned
    return this.httpClient.post('get-all-assigned-orders', data);
  }
  viewSingleOrder(data): Observable<any> { // all-orders-assigned
    return this.httpClient.post('view-order-details', data);
  }
  driverStatus(data): Observable<any> { // driver status
    return this.httpClient.post('change-duty-status', data);
  }
  getDriverStatus(data): Observable<any> { // get-driver-status
    return this.httpClient.post('get-duty-status', data);
  }
  viewOrderDetails(data): Observable<any> { // after accepting order this api will show the details of the order
    return this.httpClient.post('get-order-details', data);
  }
}
