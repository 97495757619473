<div>
  <div class="d-block d-lg-none py-1 response-nav">
    <div class="row m-0">
      <div class="col-3 text-left">
        <span class="d-flex justify-content-start burgerMenu" data-toggle="modal" data-target="#modal-menu">&#9776;</span>
      </div>
      <div class="modal left fade" id="modal-menu" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="cross-icon-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span class="text-white"
                                                                                                aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-12 text-center">
                <a class="navbar-brand" href="javascript:void(0)">
                  <img alt="" src="./assets/images/karara-logo.png" width="220" class="logoAlign pl-3 pb-3 pt-0">
                </a>
              </div>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="ProfileView()">
                    <p> <i class="fa fa-user"></i> Profile</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="AssignOrder()">
                    <p> <i class="fa fa-shopping-cart"></i> Order</p>
                  </a>
                </li>
                <li class=" nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="HistoryView()">
                    <p> <i class="fa fa-history"></i> Deliveries</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="HelpView()">
                    <p> <i class="fa fa-hand-o-left"></i> Help</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)" (click)="SettingsView()">
                    <p> <i class="fa fa-steam"></i> Settings</p>
                  </a>
                </li>
              </ul>
            </div>
          </div><!-- modal-content -->
        </div><!-- modal-dialog -->
      </div>
      <div class="col-6 text-white pt-2">
        <h6><b>Welcome To Karara</b></h6>
      </div>
      <div class="col-3">
        <a class="navbar-brand" href="javascript:void(0)">
          <img alt="" src="./assets/images/karara-logo.png" width="60px" class="logoAlign">
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-2">
    <div class="white-preview">
    <div class="btn-container">
<!--      <i class="fa fa-sun-o" aria-hidden="true"></i>-->
      <i class="fa fa-moon-o" aria-hidden="true"></i>
      <label class="switch btn-color-mode-switch">
        <input type="checkbox" name="color_mode" id="color_mode" [value]="1" (change)="ChangeDuty()" [(ngModel)]="dutyCheck">
        <label for="color_mode" data-on="ON Duty" data-off="OFF Duty" class="btn-color-mode-switch-inner"></label>
      </label>
<!--      <i class="fa fa-moon-o" aria-hidden="true"></i>-->
      <i class="fa fa-sun-o" aria-hidden="true"></i>
    </div>
    </div>
  </div>
  <div class="col-md-12 mt-2 text-left" *ngIf="allOrders[0] && loader == false">
    <h4>Orders Yet To Deliver</h4>
    <hr>
    <p class="lightFont">Remaining Orders: <b> {{allOrders.length}}</b></p>
  </div>
  <div class="divScroll" *ngIf="allOrders[0] && loader == false">
    <div class="card p-2 m-2 backStyle" *ngFor="let order of allOrders">
      <div class="row m-0 orderHeading">
      <div class="col-6">
        <a style="text-decoration: underline">
          <h6 class="styleContent">Order ID: #{{order.order_id}}</h6>
        </a>
      </div>
        <div class="col-6 text-right">
          <h6 class="styleContent"><span class="badge badge-pill badge-success">Earning: ${{order.delivery_amount + order.delivery_tip}}</span> <span *ngIf="order.delivery_tip == 0"> + <span class="fa fa-dollar" style="color: #ffa500"></span></span></h6>
        </div>
      </div>
      <div class="row mt-2 m-0">
        <div class="col-4 text-center">
          <p class="styleHeading">Assigned</p>
          <h6 class="styleContent">{{order.updated_at | date: "hh:mm a"}}</h6>
        </div>
        <div class="col-5 p-0 text-center">
          <p class="styleHeading">Total Duration</p>
          <h6 class="styleContent">{{order.total_time}}</h6>
        </div>
        <div class="col-3 text-center">
          <p class="styleHeading">Amount</p>
          <h6 class="styleContent">${{order.total_amount}}</h6>
        </div>
      </div>
      <hr style="background: white; margin: 0;">
      <div class="row m-0">
      <div class="col-5 pt-3 pl-3 p-0">
        <span class="font-weight-bold">Dist.: {{order.total_distance}}</span>
      </div>
        <div class="col-7 pt-2 p-0 text-right">
          <a class="btn btn-primary" routerLink="/delivery/{{order.id}}" >VIEW ORDER</a>
        </div>
    </div>
    </div>
  </div>
  <div *ngIf="!allOrders[0] && loader == false">
    <div class="col-12 text-center mt-5">
      <i style="font-size: 50px" class="fa fa-frown-o"> </i>
      <p>OOPS! No Order Assigned Yet</p>
    </div>
    <div class="col-12 text-center mt-2">
      <button class="btn btn-primary" (click)="refreshOrders()">REFRESH AND CHECK</button>
    </div>
    <!--<div class="col-12 text-center mt-5">
      <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
    </div>-->
  </div>
  <div *ngIf=" loader == true">
    <div class="col-12 text-center mt-5">
      <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
    </div>
  </div>
  <!--<div *ngIf="!allOrders[0]">
    <div class="col-12 text-center mt-5">
      <i style="font-size: 50px" class="fa fa-frown-o"> </i>
      <p>OOPS! No Order Assigned Yet</p>
    </div>
    <div class="col-12 text-center mt-2">
      <button class="btn btn-primary" (click)="refreshOrders()">REFRESH AND CHECK</button>
    </div>
  </div>-->
</div>
