<div class="col-12 m-0 p-0">
  <div class="text-white bgImage">
    <div class="container text-center">
      <div class="display-4 m-0 pt-5 ">
        <img src="./assets/images/karara-logo.png" width="250">
      </div>
      <h5 class="lead text-uppercase my-4">welcome to <br> driver delivery application <br>for karara</h5>
      <div class="row my-4 text-center m-0">
        <div class="col-12 form-group">
          <input type="text" placeholder="OTP HERE" required appDigitOnly class="form-control" [(ngModel)]="autoFillOtp"/>
<!--          <p class="text-uppercase p-2" style="font-size: 13px">We sent an OTP on CXX@QUxxxxBS.com & <br> +91-70XXXXXX90. Please enter in above field.</p>-->
        </div>
        <div class="col-12 mt-5 form-group">
          <button  class="btn btn-primary btn-lg form-control" (click)="VerifyOtp(autoFillOtp)">SUBMIT</button>
        </div>
        <div class="col-12 mt-2 text-center">
          <h5 class="dimColor"><a routerLink="">GO BACK</a></h5>
        </div>
      </div>
    </div>
  </div>
</div>
