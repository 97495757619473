import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../core/http/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
feedback;
  constructor(private apiServices: ApiService, private  router: Router) { }

  ngOnInit(): void {
  }

  driverFeedback() {
    const data = {
      feedback: this.feedback,
    };
    this.apiServices.driverFeedback(data).subscribe((res) => {
        console.log(res.response);
        this.router.navigate(['/restaurant']);
      }, (err) => {
        console.log(err);
      }
    );
  }

}
