<div>
  <div class="d-block d-lg-none py-1 response-nav">
    <div class="row m-0">
      <div class="col-3 text-left">
        <a class="d-flex justify-content-start burgerMenu pt-2" (click)="Back()"><i class="fa fa-arrow-left"></i></a>
      </div>
      <div class="col-6 text-white pt-2">
        <h6><b>Welcome To Karara</b></h6>
      </div>
      <div class="col-3">
        <a class="navbar-brand" href="javascript:void(0)">
          <img alt="" src="./assets/images/karara-logo.png" width="60px" class="logoAlign">
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-2 text-left">
    <h4>Settings</h4>
    <hr>
  </div>
  <div class="col-md-12 mt-5 text-center">
    Settings Section Coming Soon
  </div>
</div>
