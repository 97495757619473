import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from './core/guards/auth.guard.service';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {OtpComponent} from './components/otp/otp.component';
import {AllDeliveriesComponent} from './components/all-deliveries/all-deliveries.component';
import {AtRestaurantComponent} from './components/at-restaurant/at-restaurant.component';
import {PickFoodComponent} from './components/pick-food/pick-food.component';
import {ArrivedComponent} from './components/arrived/arrived.component';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {OrderCompleteComponent} from './components/order-complete/order-complete.component';
import {HistoryComponent} from './components/history/history.component';
import {ProfileComponent} from './components/profile/profile.component';
import {HelpComponent} from './components/help/help.component';
import {SettingsComponent} from './components/settings/settings.component';


const routes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},
  {path: 'otp', component: OtpComponent, pathMatch: 'full'},
  {path: 'delivery/:id', component: AllDeliveriesComponent, canActivate: [AuthGuardService]},
  {path: 'restaurant/:id', component: AtRestaurantComponent, canActivate: [AuthGuardService]},
  {path: 'pick-food/:id', component: PickFoodComponent, canActivate: [AuthGuardService]},
  {path: 'arrived/:id', component: ArrivedComponent, canActivate: [AuthGuardService]},
  {path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuardService]},
  {path: 'complete/:id', component: OrderCompleteComponent, canActivate: [AuthGuardService]},
  {path: 'history', component: HistoryComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'help', component: HelpComponent, canActivate: [AuthGuardService]},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
