<div class="col-12 m-0 p-0">
  <div class="text-white bgImage" *ngIf="signInObj.disabled == false">
    <div class="container text-center">
      <div class="display-4 m-0 pt-5">
        <img alt="" src="./assets/images/karara-logo.png" width="250">
      </div>
      <h3 class="lead text-uppercase my-4">welcome to <br> driver delivery application <br>for karara</h3>
      <form ngNativeValidate (ngSubmit)="signIn()">
      <div class="row my-4 m-0">
        <div class="col-12 form-group">
          <input type="email" [(ngModel)]="signInObj.email" name="email" placeholder="ENTER YOUR EMAIL" class="form-control" required>
        </div>
        <div class="col-12 form-group">
          <input type="password" [(ngModel)]="signInObj.password" name="password" appDigitOnly placeholder="ENTER YOUR PASSWORD" class="form-control" required>
        </div>
        <div class="col-12 mt-5 form-group">
          <button class="btn btn-primary btn-lg form-control" type="submit">SUBMIT</button>
        </div>
        <!--<div class="col-12 mt-5 form-group" *ngIf="signInObj.disabled == true">
          <a class="btn btn-primary btn-lg form-control" type="submit" role="button">LOADING...</a>
        </div>-->
      </div>
      </form>

    </div>
  </div>
  <div class="col-12 mt-5 text-center" *ngIf="signInObj.disabled == true">
    <img alt="" src="./assets/images/rodrigosloader.gif" width="300">
  </div>
</div>
