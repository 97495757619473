import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../core/http/api.service';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-all-deliveries',
  templateUrl: './all-deliveries.component.html',
  styleUrls: ['./all-deliveries.component.css']
})
export class AllDeliveriesComponent implements OnInit {
  loader = false;
  loaderAccept = false;
  id;
  accept = [{
    delivery_timeline: [],
  }];
  token;
  userLocation: any = {
    long: '',
    lat: '',
    address: '1600 Merivale Rd Ottawa, Ontario, ON K2G 5J8'
  };
  singleOrder;
  reason = '';
  decline;
  markerlati;
  markerlongi;
  geoWatch;
  positions;
  public lat;
  public lng;
  public origin: any;
  public destination: any;

  constructor(private apiServices: ApiService, private auth: LocalStorageService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    this.token = this.auth.getValue('driver_token', false);
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit(): void {
    this.getSingleOrder();
    this.liveLocation();

    if (window.navigator.geolocation) {
      // this.isTracking = true;
      window.navigator.geolocation.watchPosition((position) => {
        this.userLocation.long = position.coords.longitude;
        this.userLocation.lat = position.coords.latitude;
        // this.showTrackingPosition(position);
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    if (!this.geoWatch) {
      if ('geolocation' in navigator && 'watchPosition' in navigator.geolocation) {
        this.geoWatch = window.navigator.geolocation.watchPosition(
          position => {
            this.userLocation.long = position.coords.longitude;
            this.userLocation.lat = position.coords.latitude;
          });
      }
    }
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          // this.positions = position;
          this.userLocation.long = position.coords.longitude;
          this.userLocation.lat = position.coords.latitude;

          this.origin = {
            // lat: parseFloat(this.singlerideDetails.source_latitude),
            lat: 45.2972066,
            // lng: parseFloat(this.singleOrder.restaurant)
            lng: -75.8890506,
          };
          this.destination = {
            lat: 45.2956566,
            lng: -75.8845406
          };
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    }

  }
  liveLocation() {
  /*  if (window.navigator.geolocation) {
      console.log('yes');
      // this.isTracking = true;
      window.navigator.geolocation.watchPosition((position) => {
        console.log(position);
        this.userLocation.long = position.coords.longitude;
        this.userLocation.lat = position.coords.latitude;
        // this.showTrackingPosition(position);
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    if (!this.geoWatch) {
      if ('geolocation' in navigator && 'watchPosition' in navigator.geolocation) {
        this.geoWatch = window.navigator.geolocation.watchPosition(
          position => {
            this.userLocation.long = position.coords.longitude;
            this.userLocation.lat = position.coords.latitude;
          });
      }
    }*/
  }

  getSingleOrder() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      order_id: this.id,
      token: this.token
    };
    this.apiServices.viewSingleOrder(data).subscribe((res) => {
        if (res.response) {
          this.singleOrder = res.response[0];
          this.markerlati = this.userLocation.lat;
          this.markerlongi = this.userLocation.long;
          if (this.singleOrder.track_order !== 0) {
            this.router.navigate(['restaurant/' + this.id]);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  /* getAssignedOrders() {
     this.loader = true;
     const data = {
       /!* latitude: this.userLocation.lat,
        longitude: this.userLocation.long,*!/
       latitude: 45.2972066,
       longitude: -75.8890506,
       address: this.userLocation.address,
       token: this.token
     };
     this.apiServices.getPendingOrders(data).subscribe((res) => {
         if (res.response) {
           this.orders = res.response;
           if (this.orders[0].track_order !== 0) {
             this.router.navigate(['/restaurant']);
           }
           this.loader = false;
         }
       }, (err) => {
         console.log(err);
       }
     );
   }*/

  driverAcceptOrder() {
    this.loaderAccept = true;
    const data = {
      order_id: this.id,
      token: this.token, // 2
      status: 2,
    };
    this.apiServices.driverAcceptOrder(data).subscribe((res) => {
      this.loaderAccept = false;
      this.accept = res.response;
      this.auth.setValue('acceptedAt', this.accept[0].delivery_timeline[0].created_at);
      this.router.navigate(['/restaurant/' + this.id]);
      }, (err) => {
      this.loaderAccept = false;
      console.log(err);
      }
    );
  }

  driverDeclineOrder() {
    const data = {
      order_id: this.id,
      reason: this.reason,
      latitude: 45.2972066,
      longitude: -75.8890506,
      status: 1,
      token: this.token // 1
    };
    if (this.reason) {
      console.log(this.reason);
      this.apiServices.driverDeclineOrder(data).subscribe((res) => {
          this.decline = res.response;
          $('#exampleModalCenter').modal('hide');
          this.router.navigate(['/dashboard']);
        }, (err) => {
          console.log(err);
        }
      );
    }
  }

  HistoryView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/history']);
  }

  ProfileView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/profile']);
  }

  HelpView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/help']);
  }

  SettingsView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/settings']);
  }

  AssignOrder() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/dashboard']);
  }

  getAllOrders() {
    this.router.navigate(['/dashboard']);
  }

  // changeReason(id) {
  //   console.log(id);
  // }
}
