import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../core/http/api.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {environment} from '../../../environments/environment';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  otp;
  number;
  autoFillOtp;
  constructor(private apiServices: ApiService,
              private router: Router,
              private auth: LocalStorageService, private snotifyService: SnotifyService) {
  }
  ngOnInit(): void {
    this.number = this.auth.getValue('number', false);
    this.autoFillOtp = this.auth.getValue('otp', false);
  }

  VerifyOtp(otpVerify) {
    const data = {
      otp: otpVerify,
      phone_number: this.number
    };
    this.apiServices.verifyOtp(data).subscribe(res => {
      console.log(res);
      this.auth.setValue(environment.authKey, res.response[0].token);
      this.auth.setValue('token', res.response[0].token);
      console.log(res.response[0].token);
      this.router.navigate(['dashboard']);
    }, (err) => {
      this.snotifyService.error(err.error.message, err.error.response);
    });
  }

}
