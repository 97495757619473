import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {ApiService} from '../../core/http/api.service';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-arrived',
  templateUrl: './arrived.component.html',
  styleUrls: ['./arrived.component.css']
})
export class ArrivedComponent implements OnInit {
  token;
  loader = false;
  tracking = [];
  singleOrder;
  id;
  acceptedAt;
  restaurantArrivedTiming;
  pickedAt;
  public lat = 45.2972066;
  public lng = -75.8890506;
  userLocation: any = {
    long: '',
    lat: '',
    address: '1600 Merivale Rd Ottawa, Ontario, ON K2G 5J8'
  };
  markerlati;
  markerlongi;
  geoWatch;
  public origin: any;
  public destination: any;

  constructor(private auth: LocalStorageService, private apiServices: ApiService,
              private router: Router, private activatedRoute: ActivatedRoute) {
    this.token = this.auth.getValue('driver_token', false);
    this.acceptedAt = this.auth.getValue('acceptedAt', false);
    this.restaurantArrivedTiming = this.auth.getValue('restaurantArrived', false);
    this.pickedAt = this.auth.getValue('pickedAt', false);
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit(): void {
    // this.getSingleOrder();
    this.getOrderDetails();
    if (window.navigator.geolocation) {
      window.navigator.geolocation.watchPosition((position) => {
        console.log(position);
        this.userLocation.long = position.coords.longitude;
        this.userLocation.lat = position.coords.latitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    if (!this.geoWatch) {
      if ('geolocation' in navigator && 'watchPosition' in navigator.geolocation) {
        this.geoWatch = window.navigator.geolocation.watchPosition(
          position => {
            this.userLocation.long = position.coords.longitude;
            this.userLocation.lat = position.coords.latitude;
          });
      }
    }
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.userLocation.long = position.coords.longitude;
          this.userLocation.lat = position.coords.latitude;
          this.origin = {
            lat: 45.2972066,
            lng: -75.8890506,
          };
          this.destination = {
            lat: 45.2956566,
            lng: -75.8845406
          };
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    }
  }

  // getSingleOrder() {
  //   console.log(this.id);
  //   this.loader = true;
  //   const data = {
  //     latitude: 45.2972066,
  //     longitude: -75.8890506,
  //     order_id: this.id,
  //     token: this.token
  //   };
  //   this.apiServices.viewSingleOrder(data).subscribe((res) => {
  //       if (res.response) {
  //         this.singleOrder = res.response[0];
  //         this.markerlati = this.userLocation.lat;
  //         this.markerlongi = this.userLocation.long;
  //         if (this.singleOrder.track_order !== 4) {
  //           this.router.navigate(['/complete/' + this.id]);
  //         }
  //         this.loader = false;
  //       }
  //     }, (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  getOrderDetails() {
    console.log(this.id);
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      order_id: this.id,
      token: this.token
    };
    this.apiServices.viewOrderDetails(data).subscribe((res) => {
        if (res.response) {
          this.singleOrder = res.response[0];
          this.markerlati = this.userLocation.lat;
          this.markerlongi = this.userLocation.long;
          if (this.singleOrder.track_order !== 4) {
            this.router.navigate(['/complete/' + this.id]);
          }
          this.loader = false;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  /*getPendingOrders() {
    this.loader = true;
    const data = {
      latitude: 45.2972066,
      longitude: -75.8890506,
      token: this.token
    };
    this.apiServices.getPendingOrders(data).subscribe((res) => {
        this.orders = res.response;
        if (this.orders[0].track_order !== 4) {
          this.router.navigate(['/complete']);
        }
        this.loader = false;
      }, (err) => {
        console.log(err);
      }
    );
  }*/

  orderTracking() {
    const data = {
      order_id: this.id,
      status: 5, // 3 for arrived at restaurant , 4 for food picked up, 5 for arrived at customer
      token: this.token
    };
    this.apiServices.orderTracking(data).subscribe((res) => {
        this.tracking = res.response;
        this.auth.setValue('atCustomerLocation', this.tracking[0].delivery_timeline[3].created_at);
        this.router.navigate(['/complete/' + this.id]);
        console.log(res);
      }, (err) => {
        console.log(err);
      }
    );
  }

  HistoryView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/history']);
  }

  ProfileView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/profile']);
  }

  HelpView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/help']);
  }

  SettingsView() {
    $('#modal-menu').modal('hide');
    this.router.navigate(['/settings']);
  }
}
